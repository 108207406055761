import { Provider } from "react-redux";
import Head from "next/head";
import ResponsiveAppBar from "components/appbar/ResponsiveAppBar";
import Footer from "components/footer/Footer";
import { store } from "redux-toolkit/store";
import "styles/globals.css";

export default function App({ Component, pageProps }) {
  return (
    <Provider store={store}>
      <Head>
        <title>Արդար հայ եմ</title>
      </Head>
      <ResponsiveAppBar />
      <Component {...pageProps} />
      <Footer />
    </Provider>
  );
}
