import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useRouter } from "next/router";
import Image from "next/image";
import logo from "../../public/logo.svg";

const firstList = [
  { title: "Հաղորդումներ", value: "programs" },
  { title: "Վերլուծություններ", value: "analyses" },
  { title: "Հատուկ Թողարկում", value: "specials" },
  { title: "Իրադարձություններ", value: "events" },
];

const secondList = [
  { title: "Մեր մասին", value: "about-us" },
  // { title: "Միացի՜ր մեր թիմին", value: "join-us" },
  { title: "Օգտագործման պայմաններ", value: "terms-of-use" },
  { title: "Գաղտնիության Քաղաքականություն", value: "privacy-policy" },
  { title: "Կապ", value: "contact" },
];

const Footer = () => {
  const router = useRouter();
  return (
    <div>
      <div className="lg:px-20 xl:px-40 pt-4 shadow-2xl">
        <div className="flex flex-col gap-10 px-10 md:px-0 md:flex-row py-20 items-start justify-between">
          <div className="flex">
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".6rem",
                color: "black",
                textDecoration: "none",
                fontSize: "30px",
              }}
            >
              <Image
                width={80}
                height={80}
                sizes="100vh"
                src={logo}
                alt="logo"
                priority
              />
            </Typography>
            <span className="text-gray-600 text-xs font-bold w-44">
              Սույն կայքում տեղ գտած հրապարակումների հեղինակային իրավունքը
              պատկանում է բացառապես ardarhay.am ին
            </span>
          </div>
          <div className="flex flex-col">
            {firstList.map((page) => (
              <Button
                className="animated-border uppercase"
                key={page.value}
                onClick={() => {
                  router.push(page.value);
                }}
                sx={{
                  color:
                    router?.query?.category === page.value
                      ? "#9b69d4"
                      : "black",
                  textTransform: "none",
                  fontSize: "14px",
                  justifyContent: "flex-start",
                }}
              >
                <span>{page.title}</span>
              </Button>
            ))}
          </div>
          <div className="flex flex-col">
            {secondList.map((page) => (
              <Button
                className="animated-border uppercase"
                key={page.value}
                onClick={() => {
                  router.push(page.value);
                }}
                sx={{
                  color:
                    router?.query?.category === page.value
                      ? "#9b69d4"
                      : "black",
                  textTransform: "none",
                  fontSize: "14px",
                  justifyContent: "flex-start",
                }}
              >
                <span>{page.title}</span>
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <span className="text-gray-600 text-xs">© 2024 ardarhay.am</span>
      </div>
    </div>
  );
};

export default Footer;
